<template>
  <ContactProfileMain
      :contactProfile="contactProfile"
      :group="group"
      :isLoginUserProfile="false"
      @profileUpdate="getDetails"
      @onUpdateContactGroup="updateCompanyGroup"
  >
  </ContactProfileMain>
</template>

<script>
import ContactProfileMain from "@/components/molecule/contact/ContactProfileMain";
import {inject} from "vue";
import handleContact from "@/services/modules/contact";

export default {
  name: 'ContactProfileDetails',
  components: {
    ContactProfileMain:ContactProfileMain,
  },
  data() {
    return {
      contactProfile: {},
      group: '',
    }
  },

  methods: {
    async updateCompanyGroup(group, showSuccessMessage = true){
      if(this.group !== group){
        try {
          let contactId = this.$route.params.contactId;
          let companyId = this.$route.params.companyId;
          let res = await this.updateContactGroup(companyId, contactId, {
            group: group
          })

          if (!res.status) {
            this.showError(res.message)
          }
          if (res.status) {
            if(showSuccessMessage) this.showSuccess(res.message)
            this.getDetails()
          }
        } catch (err) {
          if (!err.response) {
            return this.showError('Something is wrong while updating contact group!!')
          }
          if (err.response) {
            return this.showError(err.response.message)
          }
        } finally {
          this.loading = false
        }
      }
    },
    async getDetails(showSuccessMessage = false) {
      this.loading = true;
      try {
        let contactId = this.$route.params.contactId;
        let companyId = this.$route.params.companyId;
        let res = await this.getContactDetails(companyId, contactId)

        if (!res.status) {
          this.showError(res.message)
        }
        if (res.status) {
          this.contactProfile = res.data.contact_profile
          this.group = res.data.group
          if(showSuccessMessage) this.showSuccess(res.message)
        }
      } catch (err) {
        if (!err.response) {
          return this.showError('Something is wrong.!!')
        }
        if (err.response) {
          return this.showError(err.response.message)
        }
      } finally {
        this.loading = false
      }
    },
  },

  mounted() {
    this.getDetails(true)
  },

  setup() {
    const showError = inject('showError');
    const showSuccess = inject('showSuccess');


    const {
      getCompanyContactDetails,
      updateCompanyContactGroup,
      loading,
    } = handleContact()

    return {
      getContactDetails: getCompanyContactDetails,
      updateContactGroup: updateCompanyContactGroup,
      loading,
      showError,
      showSuccess
    }
  }

}
</script>
